import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Item from 'components/Item';

const Wrapper = styled.div`
	padding: ${theme.size(4)};
	display: grid;
	grid-gap: ${theme.size(4)};
	${theme.media.large} {
		grid-template-columns: 1fr 1fr;
		> *:nth-of-type(n + 2) {
			.title {
				font-size: 1em;
			}
		}
		> *:first-of-type {
			grid-column: span 2;
			.title {
				font-size: 1.75em;
			}
			.subtitle {
				display: block;
			}
		}
	}
	${theme.media.notlarge} {
		grid-template-columns: 100%;
		grid-template-rows: auto;
	}
	.subtitle {
		display: block;
	}
`;

export default props => {
	return (
		<Wrapper>
			{props.data.map((item, i) => (
				<Item
					data={item}
					key={i}
					image={i === 0 ? 'cover' : 'landscape'}
					layout="vertical"
					subtitle={i === 0}
					lazyload={false}
				/>
			))}
		</Wrapper>
	);
};
