import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Ad from 'components/Ad';
import Feed from 'components/Feed';
import Feature from 'components/Feature';
import allPostsMin from 'data/allPostsMin';

const HomeFeed = styled.div`
	display: grid;
	grid-gap: ${theme.size(2)};
	& > * {
		background: ${theme.colors.background[0]};
		border-radius: ${theme.size(0.5)};
	}
`;

export default props => {
	let homeAllPostsMin = allPostsMin.sort((a, b) =>
		a.created_at_gmt < b.created_at_gmt ? 1 : b.created_at_gmt < a.created_at_gmt ? -1 : 0,
	);
	homeAllPostsMin = [...homeAllPostsMin, ...homeAllPostsMin];
	return (
		<HomeFeed>
			<Feature data={homeAllPostsMin.slice(0, 3)} />
			<div>
				<Ad type="leaderboard_bot" />
				<Ad type="mpu_2" />
			</div>
			<div className="feed">
				<Feed data={homeAllPostsMin.slice(3, 9)} />
				<Ad type="leaderboard_bot_2" />
				<Ad type="mpu_3" />

				<Feed data={homeAllPostsMin.slice(9, 15)} />
				<Ad type="leaderboard_bot_3" />
				<Ad type="mpu_4" />

				<Feed data={homeAllPostsMin.slice(15, 21)} />
				<Ad type="leaderboard_bot_4" />
				<Ad type="mpu_5" />

				<Feed data={homeAllPostsMin.slice(21, 27)} />
				<Ad type="leaderboard_bot_5" />
			</div>
		</HomeFeed>
	);
};
